'use strict';

angular.module('webPartnerPortalApp')
  .controller('LogoutCtrl', ['$scope','$http', '$location',function ($scope, $http, $location) {
    var LogoutCtrlVM = this;
    LogoutCtrlVM.logout = function(){
    	$http({
		  method: 'GET',
		  url: '/api/logouts',
		}).then(function successCallback() {
		    window.location.url = '/';
            $location.url('/login');
	  	}, function errorCallback() {

        });
    };

    LogoutCtrlVM.init = function(){
    	LogoutCtrlVM.logout();
    };
    LogoutCtrlVM.init();

  }]);
